/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
/* Prime NG Table CSS */
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */
@import "./app/app.scss";
@import "../node_modules/aar-ionic-component-library/dist/ngx-aar-ionic-library/assets/scss/variables.scss";

// Reusable Variables starts here
:root {
  --side-nav-width: 115px;
}

// Allows user to copy/paste text
* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.td-select {
  // Explicitly added for use with Windows OS
  user-select: text;
}

// Font colors
$font-color-blue: #2b517c;
$font-color-medium: #6c6c6c;
$font-color-lightdark: #494949;
$font-color-black: #000000;

// side nav panel
.split-pane {
  --border: none;

  .sidenav {
    min-width: var(--side-nav-width);
    max-width: var(--side-nav-width);
    background: var(--concourse-nav-background);
    --background: var(--concourse-nav-background);
    background-color: var(--concourse-nav-background);

    app-sidenav {
      height: 100vh;
      margin-bottom: 15px;
    }
  }
}

// Structure CSS starts here
.pointer, .cursor-pointer, .aar-clickable-link {
  cursor: pointer;
}
.float-right {
  float: right;
}
.display-flex {
  display: flex;
}
.display-block {
  display: block;
}
.center-align-items {
  align-items: center;
}
.end-align-items {
  align-items: end;
}
.center-align-self {
  align-self: center;
}
.end-align-self {
  align-self: end;
}
.text-align-left {
  text-align: start;
}
.text-align-right {
  text-align: end;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
// Structure CSS ends here

// TODO: Please delete the following
.border-btm-2-grey {
  border-bottom: 2px solid #eaeaea;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-normal {
  font-weight: normal;
}

.padding-left2 {
  padding-left: 2%;
}
.padding-top2 {
  padding-top: 2%;
}
.padding-top0 {
  padding-top: 0;
}
.top-6px {
  top: 6px;
}
.padding-2 {
  padding: 2% 5%;
  padding-bottom: 50px;
}
.padding-0 {
  padding: 0px;
}
.padding-1 {
  padding: 1%;
}
.padding-5 {
  padding: 5%;
}
.padding-20 {
  padding: 20px;
}
.padding-top-0 {
  padding-top: 0%;
}
.padding-top-20 {
  padding-top: 17px;
}
.padding-btm-30 {
  padding-bottom: 30px;
}
.padding-16 {
  padding: 21px 16px;
}
.padding-top-15 {
  padding-top: 1.5%;
}
.padding-column-text {
  padding-left: 15px;
}
.padding-left-6 {
  padding-left: 6px;
}
.padding-left-20px {
  padding-left: 20px;
}
.padding-left-16px {
  padding-left: 16px;
}
.padding-assigned {
  padding: 12px 6px;
}
.padding-row-text {
  padding-left: 8px;
}

.margin-top {
  margin-top: 2%;
}

.roleModal-popover div.popover-content{
  top: 30% !important;
  left: 50%!important;
  transform: translate(-50%, -50%) !important;
  --width: 400px;
  --max-width: 100%;
}

.orgModal-popover div.popover-content{
  top: 30% !important;
  left: 50%!important;
  transform: translate(-50%, -50%) !important;
  --width: 400px;
  --max-width: 100%;
}

.popover-auto-width {
  --width: unset;
  --max-width: unset;
}

div.display-parts ion-list lib-aar-radio-button-group ion-list-header.md.hydrated {
  display: none !important;
}

ion-popover {
  &[popover]:not([popover-open]):not(dialog[open]) {
    display: contents !important;
  }
}

 lib-aar-checkbox {
  ion-item{
    --padding-start: 0;
  }
}

/* PrimeNg Table filter overlay styles - for Light/Dark Mode compatability */
/* The way primeng adds the filter overlays to the DOM makes it impossible to access these styles from within the PTable
  component in the UICL, which is why these styles were added here. */

div.p-column-filter-overlay {
  background: var(--ion-color-background);
  border: 1px solid var(--ion-color-step-150);
  div.p-column-filter-operator {
    background: var(--ion-color-background);
    p-dropdown {
      div.p-column-filter-operator-dropdown {
        background: var(--ion-color-background);

        .p-dropdown-label{
          color:var(--ion-color-text) !important;
        }
        div.p-dropdown-panel {
          background: var(--ion-color-background);
          div.p-dropdown-items-wrapper {
            ul.p-dropdown-items {
              p-dropdownitem {
                .p-dropdown-item{
                  color:var(--ion-color-text) !important;
                }
                li.p-highlight {
                  background: var(--ion-color-step-150);
                }
                li:hover {
                  background: var(--ion-color-step-150);
                }
              }
            }
          }
        }
      }
    }
  }
  div.p-column-filter-constraints {
    div.p-column-filter-constraint {
      p-dropdown {
        div.p-column-filter-matchmode-dropdown {
          background: var(--ion-color-background);
          .p-dropdown-label{
            color:var(--ion-color-text);
          }
          div.p-dropdown-panel {
            background: var(--ion-color-background);
            div.p-dropdown-items-wrapper {
              ul.p-dropdown-items {
                p-dropdownitem {
                  .p-dropdown-item{
                    color:var(--ion-color-text);
                  }
                  li.p-highlight {
                    background: var(--ion-color-step-150);
                  }
                  li:hover {
                    background: var(--ion-color-step-150);
                  }
                }
              }
            }
          }
        }
      }
      p-columnfilterformelement {
        input {
          background: var(--ion-color-background);
          color:var(--ion-color-text);
        }
        p-calendar {
          div.p-datepicker:not(.p-datepicker-inline) {
            background: var(--ion-color-background);
            div.p-datepicker-group-container {
              color:var(--ion-color-text);
              div.p-datepicker-group {
                div.p-datepicker-header {
                  background: var(--ion-color-background);
                }
              }
            }
          }
          .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {

            color:var(--ion-color-black);
        }
        }
        p-multiselect {
          div.p-multiselect {
            background: var(--ion-color-background);
            .p-multiselect-label-container{
              .p-multiselect-label {
                color:var(--ion-color-text);
              }
            }
            div.p-multiselect-panel {
              background: var(--ion-color-background);
              div.p-multiselect-header {
                background: var(--ion-color-background);
                div.p-checkbox {
                  div.p-checkbox-box {
                    .p-checkbox-icon {
                      color:var(--ion-color-background) !important;
                      background:  var(--ion-color-tertiary) !important;}
                  }
                }
              }
              div.p-multiselect-items-wrapper {
                ul {
                  p-multiselectitem {
                    li {
                      color:var(--ion-color-text);
                      &:hover {
                        background: var(--ion-color-step-150);
                      }
                      &.p-highlight {
                        background: var(--ion-color-step-150);
                      }
                      div.p-checkbox {
                        div.p-checkbox-box {
                          .p-checkbox-icon {
                          color:var(--ion-color-background) !important;
                          background: var(--ion-color-tertiary) !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
