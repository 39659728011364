a {
  text-decoration: none !important;
}
app-request-pin-modal {
  .pin-modal {
    lib-aar-modal {
      .show-modal {
        .modal-popup {
          width: 25rem !important;
          height: 16rem !important;

        }
      }
    }
  }
}

.p-datatable.p-datatable-base ion-chip {
  height: fit-content;
}


